div.flash {
  color: $text-dark;
  border: $b-dark;
  background: $white;
  padding: 1rem;
  margin: 0 auto 1.5rem auto;
  max-width: 100%;
  border-radius: $border-radius;

  &.error {
    color: $danger;
    border: $b-danger;
  }

  &.info {
    color: $info;
    border: $b-info;
  }

  &.success {
    color: $success;
    border: $b-success;
  }

  &.warning {
    color: $warning;
    border: $b-warning;
  }
}