
				@use "sass:map";
				@import "./www/assets/styles/_variables.scss";
		
.answers-histogram {
  .chart {
    padding-top: 1rem;
    .chart-heading {
      font-size: 84%;
    }
    .chart-body {
      padding-bottom: 1rem;
    }
  }
  .chart:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .select2 {
    .select2-selection__choice {
      font-size: 82%;
    }
    .select2-search {
      line-height: 1;
    }
  }
}

.select2-answers-histogram-dropdown .select2-results__option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
