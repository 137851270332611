// Column width setup
//
.page-Projects-list {
  .datagrid table tbody td {
    min-width: unset;
    vertical-align: top;
  }

  .col-project {
    min-width: 200px;
    width: 30%;
  }
  .col-tags {
    min-width: 250px;
  }
  .col-marks,
  .col-FinishedCount {
    max-width: 90px;
    //font-weight: bold;
    color: $gray-500;
  }

  .card .card-body .table thead tr:last-child th:last-child { border-radius: 0; }

  .badge { cursor: pointer; }
  .table-striped .badge-light {
    background-color: $gray-lighter;
    border-left-color: $gray-light;

    &:hover,
    &:focus {
      background-color: $gray-light;
      border-left-color: $gray-600;
    }
  }
}