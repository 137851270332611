

.project-marks {
  display: inline-block;
  float: right;
  height: 37px;
}


.project-mark {
  display: inline-block;
  height: 20px;
  font-weight: bold;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1;
  white-space: nowrap;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    text-decoration: none;
  }

  &.loi-out-of-expectation {
    width: 16px;
    background-image: url("../../images/icons/clock-alert.svg");
    background-size: 16px;
  }

  &.respondent-has-problem {
    width: 16px;
    background-image: url("../../images/icons/alert-octagon.svg");
    background-size: 16px;
    color: transparent;
    font-size: 6px;
    text-align: left;
    position: relative;

    &.no-warning {
      filter: unset;
      opacity: 0.5;
      transition: opacity 150ms ease-out;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  &.client-give-comment {
    width: 16px;
    background-image: url("../../images/icons/message.svg");
    background-size: 16px;
    color: transparent;
    font-size: 6px;
    text-align: left;
    position: relative;

    &.no-warning {
      opacity: 0.5;
      transition: opacity 100ms ease-out;

      &:hover,
      &:focus {
        opacity: 1;
        transition: opacity 100ms ease-out;
      }
    }
  }
}


.nav-bar .project-mark.respondent-has-problem,
.nav-bar .project-mark.client-give-comment,
.datagrid .project-mark.respondent-has-problem,
.datagrid .project-mark.client-give-comment,
.datagrid .project-mark.loi-out-of-expectation,
{
  padding: 10px 0;
  position: relative;
}
.nav-bar .project-mark.respondent-has-problem {
  background-image: url("../../images/icons/alert-octagon-red.svg");
}
.nav-bar .project-mark.client-give-comment {
  background-image: url("../../images/icons/message-red.svg");
}
.nav-bar .project-mark.no-warning {
  opacity: 1;
}
.nav-bar .project-mark.respondent-has-problem.no-warning {
  background-image: url("../../images/icons/alert-octagon.svg");
}
.nav-bar .project-mark.client-give-comment.no-warning {
  background-image: url("../../images/icons/message.svg");
}

.nav-bar .project-mark .notification-symbols,
.project-mark .notification-symbols {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.notification-symbols {
  .notification-symbol {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: -4px;

    &.notification-symbol-new {
      background: #e22100;
      box-shadow: 0 4px 8px rgba(135, 23, 0, 0.3);
    }

    &.notification-symbol-check_am {
      background: #af60ff;
      box-shadow: 0 4px 8px rgba(63, 0, 135, 0.3);
    }

    &.notification-symbol-check_anal {
      background: #4583ff;
      box-shadow: 0 4px 8px rgba(0, 55, 135, 0.3);
    }
  }
}