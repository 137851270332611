.questionaryComments {
  .questionaryComment {
    border: 1px solid #aaaaaa;
    background: white;
    position: relative;
    margin-bottom: 20px;

    &.questionaryComment-new .questionaryComment-status {
      background: #0ea737;
      color: white;
    }

    &.questionaryComment-invalid .questionaryComment-status {
      background: #ffe7e9;
      color: #a9a9a9;
    }

    &.questionaryComment-settled .questionaryComment-status {
      background: #eaffec;
      color: #a9a9a9;
    }

    &.questionaryComment-check_am .questionaryComment-status {
      background: #b37dea;
      color: #ffffff;
    }

    &.questionaryComment-check_anal .questionaryComment-status {
      background: #5c94fb;
      color: #ffffff;
    }

    .questionaryComment-heading {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 10px;
      border-bottom: 1px solid #F1F1F1;
      font-size: 12px;

      .questionaryComment-status {
        font-weight: bolder;
        font-size: 12px;
        padding: 2px 8px;
        border-radius: 20px;
      }
    }


    .questionaryComment-body {
      padding: 10px 10px;
      border-bottom: 1px solid #F1F1F1;

      .questionaryComment-answers-showAll a {
        font-size: 12px;
      }

      .questionaryComment-answers {
        margin-left: 5px;

        .questionaryComment-answer {
          position: relative;
          margin-top: 10px;
          padding-left: 10px;
          font-size: 12px;

          &::before {
            content: '»';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
          }
        }
      }

      .action-reply {
        float: right;
      }
    }

    .date {
      color: #888;
      font-family: "Consolas", "Menlo", "Courier", monospace;
    }

    .questionaryComment-footer {
      padding: 4px 10px 0 10px;
      display: flex;
      justify-content: space-between;
      background: #FCFCFC;
      font-size: 12px;
      width: 100%;

      .date {
        margin-right: 20px;
      }
    }

    .questionaryComment-actions a {
      margin-left: 5px;

      .icon {
        display: inline-flex;
        height: 12px;
        width: 15px;
        top: 2px;
        &::before {
          font-size: 19px;
          line-height: 12px;
        }
      }
    }
  }
}
