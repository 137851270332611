
				@use "sass:map";
				@import "./www/assets/styles/_variables.scss";
		
// VARIABLES
@import "~nms-design-system-core/scss/variables/variables";
//@import "styles/variables";

// Libs
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-table/src/bootstrap-table";
@import "~bootstrap-select/sass/bootstrap-select";

@import "styles/lib/datagrid/datagrid.css";
@import "styles/lib/datagrid/datagrid-spinners.css";
@import "styles/codemirror";
@import "jquery-ui/dist/themes/base/jquery-ui.css";
@import "jquery-ui/dist/themes/base/theme.css";


// Import parts of nms design
@import "~nms-design-system-core/scss/layout/layout";

@import "~nms-design-system-core/scss/components/alert";
@import "~nms-design-system-core/scss/components/buttons";
@import "~nms-design-system-core/scss/components/badge";
@import "~nms-design-system-core/scss/components/card";
@import "~nms-design-system-core/scss/components/dropdowns";
@import "~nms-design-system-core/scss/components/form";
@import "~nms-design-system-core/scss/components/input";
@import "~nms-design-system-core/scss/components/navbar";
@import "~nms-design-system-core/scss/components/navs";
@import "~nms-design-system-core/scss/components/modal/modal";

@import "~nms-design-system-core/scss/content/tables";
@import "~nms-design-system-core/scss/content/typography";
//@import "components/UserProfile";

@import "~nms-design-system-core/scss/extend/icons/icon-font";

@import "~nms-design-system-icon/dist/main";

@import "~nms-design-system-core/scss/datagrid/datagrid";

// Layout
@import "styles/layout.scss";
@import "styles/print.scss";
@import "styles/animations.scss";

// Components
@import "styles/component/badge.scss";
@import "styles/component/buttons.scss";
@import "styles/component/datagrid.scss";
@import "styles/component/dropdowns.scss";
@import "styles/component/flash.scss";
@import "styles/component/icon.scss";
@import "styles/component/mjml-editor.scss";
@import "styles/component/projectComment.scss";
@import "styles/component/projectMark.scss";
@import "styles/component/questionaryComment.scss";
@import "styles/component/unsubscribe.scss";

// Pages
@import "styles/page/appearance.scss";
@import "styles/page/dashboard.scss";
@import "styles/page/list-of-projects.scss";
@import "styles/page/observer.scss";
@import "styles/page/sign.scss";
@import "styles/page/sign-forgotten-password.scss";

@import "styles/page/sign-in-sedo.scss";
