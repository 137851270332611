
				@use "sass:map";
				@import "./www/assets/styles/_variables.scss";
		
.TagsFavouriteContainer {
  display: flex;
  gap: 0.5rem;

  .tag-add-button:not(.disabled) {
    position: relative;
    cursor: pointer;

    &:before {
      content: '+';
      font-size: 12px;
      position: absolute;
      top: 10px;
      left: 3px;
      z-index: 1;
      text-shadow: 1px -1px 0px white, 1px 1px 0px white, -1px -1px 0px white, -1px 1px 0px white, -1px 0px 0px white, 1px 0px 0px white, 0px -1px 0px white, 0px 1px 0px white;
    }

    > .icon {
      width: 24px;
      height: 24px;
    }
  }

  .tag-add-button.disabled {
    padding: 0.5rem 0.25rem;
    width: 24px;
    height: 24px;
  }

}