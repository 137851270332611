body {
  background: #f7f7f7;
  transition: filter 1.5s, background-color 1.5s;
  transition-delay: 1s;
}

.padding-top-2 { padding-top: 2px; }

.vertical-align-bottom { vertical-align: bottom; }
.vertical-align-middle { vertical-align: middle; }

table thead tr th.padding-bottom-2,
table thead tr td.padding-bottom-2 { padding-bottom: 2px; }

#ajax-spinner {
	margin: 15px 0 0 15px;
	padding: 13px;
	background: white url('../images/spinner.gif') no-repeat 50% 50%;
	font-size: 0;
	z-index: 123456;
	display: none;
}

html.js .jshidden { display: none; }

#footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 120px;
	text-align: center;
	padding: 10px 0 20px 0;
	background-color: #ecfaff;
	border-top: 1px dotted #b6edff;
}

#cke_frm-endPageForm-end_page_html { margin-bottom: 1rem; }
#frm-exportForm-statuses { height: 105px; }

/* Content */
h2 span { font-weight: 400; }
h2.appearance-headline { font-size: 1.5rem; }
ul,
ol,
dl { font-size: 0.875rem; }
p {
	font-size: 1rem;

	&.text-large { font-size: 1.5rem; }
}

.language {
	text-transform: uppercase;
	font-size: 0.725rem;
	font-weight: 600;
}

.tooltip {
	font-size: 0.8rem;
	padding: 10px;
}
.has-tip {
  cursor: help;

  i { color: #008CBA; }
}

.top-bar-section .has-dropdown:hover > a:after { border-color: rgba(255, 255, 255, 0.65) transparent transparent transparent; }

textarea { resize: vertical; }

span.has-tip { font-weight: 400; }

.status-ok { color: #43AC6A; }
.status-pending { color: #F08A24; }
.status-incomplete { color: #F04124; }

tr.inactive { opacity: 0.4; }
td.inactive,
td.inactive a { color: #F04124; }

.datagrid table tbody td { min-width: 95px; }   // CAWI-222 Fix pole tabulky jsou při editaci zrdcnuta

.col-action .ico{ font-size: 1rem; }
.dropdown-pane.open {
  display: block;
  visibility: visible;
  min-width: 300px;
  padding: 1rem;
}
.tabs-content{
  border: 1px solid #dddddd;
  padding: 1rem;
  background: #fff;
}
.tabs{
  border: 1px solid #dddddd;
  border-bottom: none;
  background: #efefef;
}

.hidden { display: none !important; }
.clickable,
.cursor-pointer { cursor: pointer; }
.cursor-help { cursor: help; }
.not-allowed { cursor: not-allowed !important; }

/* Přizpůsobení výchozího NMS layoutu pro CAWI */
.body-wrap > .content-wrap {
	> .container {
		padding: $content-p;
		flex-grow: 1;
		overflow-x: hidden;
	}

	> .nav { box-shadow: 0 4px 8px rgba(84, 110, 122, 0.16), 0 2px 4px rgb(13, 45, 60, 0.16); }
}
@include media-breakpoint-up(lg) {
	.body-wrap > .content-wrap > .nav { justify-content: center; }
	.body-wrap > .content-wrap > .nav + .container { padding-top: calc(#{$content-p} + #{$navs-h}); }
}
@include  media-breakpoint-down(md) {
	.navbar .navbar-nav .dropdown-menu { position: absolute; }
}

/* Změna nastavení navbaru */
.navbar {
	//background: $primary-darker;

	.navbar-brand {
		padding-top: 0;

		> span { padding-top: 0.25rem; }
	}
}

/* Upravené formátování karet pro použití v CAWI gridu */
.card {
	height: calc(100% - 20px);
	margin-bottom: 20px;

	/* Formátování nafixlovaných grafů v kartách na dashboardu */
	.card-body img { width: 100%; }
}

/* Rozmazání nefunkčních komponent na dashboardu */
.opacity-5 {
	opacity: 0.5;
	filter: blur(1px);
}

/* Formátování datagridu pro použití v CAWI gridu */
.datagrid { padding: 0; }

/* Formátování vlaječek u nabídky jazyků v hlavním menu */
nav.navbar .navbar-nav .dropdown-menu .dropdown-item > img { margin: 0 0.875rem 0 0; }

/* zarovnání textu vedle tlačítek s akcemi na střed vertikálně */
.card .card-header .btn-group {
  align-items: center;
}


/* TODO z-index policy */
.body-wrap {
  .content-wrap {
	  z-index: unset !important;

	  > .nav-tabs { z-index: 10 !important; }
  }
  .sidebar  { z-index: 11 !important; }
}
nav.navbar { z-index: 100 !important; }
// modals 1000 ok
// flashmessages 10_000

.line-height-inherit {
	line-height: inherit;
}

.disabled {
  pointer-events: none;
  opacity: 0.8;
  filter: saturate(0.7);
  cursor: not-allowed;
}

.modal {
	.modal-body {
		img {
			max-width: 100%;
		}
	}
}


.card .card-header div > span.select2 { min-width: 150px; }

.hover-background-light-primary:hover {
  background-color: $bg-light-primary;
}