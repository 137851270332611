.datagrid {
  td.col-action-inline-edit {
    input[type="submit"] {
      display: inline-block;
      width: auto;
      height: auto;
    }
  }

  td.col-action .btn:not(:last-child) {
    margin-right: 5px;
  }

  .text-right .column-header {
    justify-content: end;
  }

  tfoot .col-pagination .btn {
    width: 30px;
  }
}
.table .thead-primary th {
  color: $white;
  background-color: $primary !important;
  border: none;

  a,
  .icon { color: $white; }
}

.ublaboo-spinner.ublaboo-spinner-small {
  width: 28px;
  height: 28px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}