@import "~codemirror";
@import "~codemirror/addon/hint/show-hint.css";

.CodeMirror {
  border: 1px solid #eee;
  height: auto;
  resize: vertical;

  * {
    font-size: 12px;
  }
}