
				@use "sass:map";
				@import "./www/assets/styles/_variables.scss";
		
.TagAssign {
  width: 300px;
  background-color: white;
  height: 300px;
  padding: 1rem;
  z-index: 1;
  border-radius: $b-radius-default;
  box-shadow: 0 0 32px rgba(0,0,0,0.12);

  .tag-filter {
    .tag-filter-color {
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
    }
  }

}