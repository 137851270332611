// General
//
.dashboard,
.dashboard-sidebar {
  .card .card-body > .tab-pane.active { display: flex; }
}

// Data
//
.dashboard,
.dashboard-sidebar {
  .results-number,
  .project-info {
    margin-top: 1rem;

    &.main {
      width: calc(100% + 1rem);
      margin: 0 -0.5rem -0.5rem -0.5rem;
      padding: 0.5rem;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 6px;

      .title {
        font-weight: 800;
        color: #66B536;
        opacity: 1;
      }

      .value { font-weight: 600; }
    }

    .title {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #3A3A3A;
      opacity: 0.6;
    }

    .value {
      margin: 5px 0 0 0;
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.75rem;
      color: #3A3A3A;
    }

    &.copy {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .div { flex: 1; }
      .title { line-height: 30px; }

      .icon {
        width: 30px;
        height: 30px;
        color: $text-dark;

        &::before { font-size: 30px; }
      }
    }
  }

  .results-number {
    min-width: 120px;
    width: 50%;

    &.main { background: rgba(102, 181, 54, 0.2); }
    .value { font-size: 1.5rem; }
  }

  .urlList {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    &:first-child { margin-top: 0; }

    > div:first-child {
      width: calc(100% - 63px);
      flex: 1;
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      span { margin-left: 8px; }
    }

    .title {
      margin: 0.2rem 0;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      color: #3A3A3A;
    }

    .url {
      margin: 5px 0 0 0;
      font-style: normal;
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #3A3A3A;
      opacity: 0.6;
    }

    .btn {
      padding: 0;

      .icon {
        width: 30px;
        height: 30px;

        &::before { font-size: 30px; }
      }
    }
  }

  .card .project-settings {
    > div {
      display: flex;
      align-items: center;
      margin-left: -1rem;
      margin-right: -1rem;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #EEE;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .icon {
        width: 1.5rem;
        height: 1.5rem;
        flex: 0 0 auto;

        &::before { font-size: 1.5rem; }
      }

      p {
        margin-left: 0.5rem;
        margin-bottom: 0;
        flex: 1 1 auto;
        font-size: 0.875rem;
      }
    }
  }

  .card.comments {
    .badge { line-height: 100%; }
    .badge.pink { background: $pink; }

    .status {
      color: red;

      &.status-check_am { color: #b37dea; }
      &.status-check_anal { color: #5c94fb; }
      &.status-new { color: $color-green-success; }
      &.status-invalid { color: #ffe7e9; }
    }
  }
}
