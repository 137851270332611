@media print {
  body {
    font: 12pt/1.4 "Trebuchet MS", "Geneva CE", lucida, sans-serif;
    color: black;
    background: none;
    width: 100%;
  }

  a img { border: none; }

  #ajax-spinner {
    display: none;
  }

}
