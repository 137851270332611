// Adjusts which functions in the MJML editor each role will see.
//

.role-field {
  // Top panel
  span.gjs-pn-btn.fa.fa-edit,
  span.gjs-pn-btn.fa.fa-download {
    display: none;
  }

  // Widgets
  .gjs-fonts.gjs-f-b2.gjs-block.gjs-one-bg.gjs-four-color-h,
  .gjs-fonts.gjs-f-b3.gjs-block.gjs-one-bg.gjs-four-color-h,
  .gjs-fonts.gjs-f-divider.gjs-block.gjs-one-bg.gjs-four-color-h,
  .fa.fa-arrows-v.gjs-block.gjs-one-bg.gjs-four-color-h,
  .fa.fa-bars.gjs-block.gjs-one-bg.gjs-four-color-h,
  .fa.fa-id-card.gjs-block.gjs-one-bg.gjs-four-color-h,
  .fa.fa-window-maximize.gjs-block.gjs-one-bg.gjs-four-color-h,
  .fa.fa-share-alt.gjs-block.gjs-one-bg.gjs-four-color-h,
  .gjs-fonts.gjs-f-button.gjs-block.gjs-one-bg.gjs-four-color-h {
    display: none;
  }

  // Layers
  .gjs-layer.gjs-layer__t-mj-head.no-select.gjs-two-color {
    display: none;
  }

  // Settings

  // Styles
  .gjs-sm-property.gjs-sm-number.gjs-sm-integer.gjs-sm-property__max-width,
  .gjs-sm-property.gjs-sm-composite.gjs-sm-property__margin.gjs-sm-property--full,
  .gjs-sm-property.gjs-sm-number.gjs-sm-integer.gjs-sm-property__letter-spacing,
  .gjs-sm-property.gjs-sm-number.gjs-sm-integer.gjs-sm-property__line-height,
  .gjs-sm-property.gjs-sm-radio.gjs-sm-property__align.gjs-sm-property--full,
  .gjs-sm-property.gjs-sm-radio.gjs-sm-property__font-style.gjs-sm-property--full,
  .gjs-sm-property.gjs-sm-radio.gjs-sm-property__text-decoration.gjs-sm-property--full,
  .gjs-sm-property.gjs-sm-file.gjs-sm-property__background-url,
  .gjs-sm-property.gjs-sm-select.gjs-sm-property__background-repeat,
  .gjs-sm-property.gjs-sm-select.gjs-sm-property__background-size,
  .gjs-sm-property.gjs-sm-composite.gjs-sm-property__border-radius.gjs-sm-property--full,
  .gjs-sm-property.gjs-sm-composite.gjs-sm-property__border.gjs-sm-property--full,
  .gjs-sm-property.gjs-sm-composite.gjs-sm-property__border-detached.gjs-sm-property--full {
    display: none;
  }

  // Styles - Font list
  option[value="Arial Black, Gadget, sans-serif"],
  option[value="Brush Script MT, sans-serif"],
  option[value="Comic Sans MS, cursive, sans-serif"],
  option[value="Courier New, Courier, monospace"],
  option[value="Impact, Charcoal, sans-serif"],
  option[value="Lucida Sans Unicode, Lucida Grande, sans-serif"],
  option[value="Tahoma, Geneva, sans-serif"],
  option[value="Trebuchet MS, Helvetica, sans-serif"] {
    display: none;
  }
}
