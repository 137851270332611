
				@use "sass:map";
				@import "./www/assets/styles/_variables.scss";
		
.CommunicationTable {
  tbody td {
    label {
      margin-bottom: 0;
      > input[type="checkbox"] {
        position: relative;
        margin-right: 5px;
        top: -3px;
      }
    }
  }
}