.page-Sign.theme-sedo  {
  min-width: unset;
  display: flex;
  background: url("../../images/login-page/background.png"), linear-gradient(133.62deg, #ad2626 7.57%, #921b1b 92.45%), #ad2626;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  #content {
    margin: auto;

    @media screen and (max-width: 768px) {
      padding: 3rem;
    }

    @media screen and (max-width: 499px) {
      padding: 0;
    }

    .small-12 {
      display: flex;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      @media screen and (max-width: 499px) {
        padding: 0;
      }
    }
  }

  #snippet--flashes {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
  }

  .login img,
  .login-content img {
    margin: auto;
    display: block;
  }
  .login img {
    margin-bottom: 40px;
  }


  .login {
    width: 100%;
    max-width: 375px;
    padding: 60px;
    background: #FFFFFF;
  }

  @media screen and (max-width: 768px) {
    #snippet--flashes { display: none; }
    .login { max-width: unset; }

    .login hr {
      max-width: 300px;
      margin: 1.25rem auto 1.1875rem auto;
    }
  }
  @media screen and (max-width: 499px) {
    .login { padding: 60px 30px; }
  }

  .login-content {
    width: 100%;
    max-width: 695px;
    padding: 0 60px 60px 60px;
    background: #ad2626;
  }

  @media screen and (max-width: 768px) {
    .login-content {
      max-width: unset;
      box-shadow: inset 0 4px 4px -4px rgba(0,0,0,0.5);
    }
  }

  @media screen and (max-width: 499px) {
    .login-content {
      padding: 0 30px 30px 30px;
      background: rgba(173, 28, 28, 0.35);
    }
  }
  .login-brand-logo {
    max-height: 100px;
    margin-top: 40px;
  }

  .login-content p {
    width: 100%;
    max-width: 400px;
    margin: 40px auto 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #FFFFFF;
  }

  .login-content a {
    text-decoration: underline;
    color: #FFFFFF;
  }

  .ico {
    padding: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .ico-web { background-image: url("../../images/login-page/web.svg"); }
  .ico-facebook { background-image: url("../../images/login-page/facebook.svg"); }
  .ico-linkedin { background-image: url("../../images/login-page/linkedin-box.svg"); }
  .ico-google { background-image: url("../../images/login-page/google.svg"); }

  .login {
    table {
      margin: auto;
      padding: 0;
      border: none;
      @media screen and (max-width: 768px) {
        max-width: 300px;
      }

      tr,
      tr th,
      tr td {
        padding: 0;
        display: block;
      }

      th {
        margin-top: 20px;
      }

      .button:hover,
      .button:focus,
      input[type="submit"].button:hover,
      input[type="submit"].button:focus {
        color: #FFFFFF;
        box-shadow: 0 6px 8px rgba(25, 100, 170, 0.24);
      }

      .button:active,
      input[type="submit"].button:active {
        color: #FFFFFF;
        box-shadow: 0 0 0 rgba(0, 0, 0, 1);
      }
    }

    table .caption a:hover,
    table .caption a:focus,
    .forgot-pass a:hover,
    .forgot-pass a:focus {
      background: rgba(0, 0, 0, 0.1);
    }

    label.required {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      align-items: center;
      letter-spacing: 0.4px;
      text-align: left;
      color: #909090;
    }

    input[type="email"],
    input[type="password"] {
      border: 1px solid #CCCCCC;
      box-sizing: border-box;
      border-radius: $border-radius;
      text-align: left;
    }

    input.has-error {
      border-color: #cf2a0e;
    }

    input[type="email"] + .text-danger,
    input[type="password"] + .text-danger {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.4px;
    }

    input[type="email"] + .text-danger {
      margin-top: -10px;
    }

    table .caption a,
    .forgot-pass a,
    table .button,
    table input[type="submit"].button {
      width: 100%;
      height: 40px;
      padding: 0 1rem;
      display: flex;
      background: #ad2626;
      box-shadow: 0 4px 8px rgba(173, 28, 28, 0.24);
      border-radius: $border-radius;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.75px;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    table {
      .button:hover,
      .button:focus,
      input[type="submit"].button:hover,
      input[type="submit"].button:focus {
        box-shadow: 0 4px 8px rgba(173, 28, 28, 0.24);
      }
    }
  }


  .login table .caption a,
  .login .forgot-pass a {
    background: #FFFFFF;
    box-shadow: unset;
    color: #909090;
    align-items: center;
    margin-top: 10px;
  }

}

.page-Sign-newPassword {
  .row .row { margin: auto; }

  .small-12.login {
    display: block;
    min-width: 350px;
    max-width: unset;
  }
}
@media screen and (max-width: 499px) {
  .page-Sign-newPassword {
    .row .row { margin: auto; }

    .small-12.login {
      min-width: unset;
      padding: 60px;
    }
  }
}