// VARIABLES
//
// Pro nastavení vlastní barevné palety zkopírujte příslušné proměnné ze souboru node_modules/nms-design-system-core/scss/variables/colors.scss



// NMS color palette
//
// Brand colors
$primary: #4E9621;
$accent: #E67E22;
$tertiary: #E67E22;
$blue: #1964AA;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "accent":     $accent,
    "tertiary":   $accent,
  ),
  $theme-colors
);

// Brand complementary colors (recommended)
//
// All recommended complementary colors you can find in the extended-complementary-colors.scss file.
// If you want to change Brand color palette, copy extended color palette for all colors what you want to use.
// For copied colors, be sure to change the color prefix (primary, secondary, tertiary etc...).
//
$primary-light: #95D171;
$primary-lighter: #C3E5AE;
$primary-lightest: #EEF7E9;
$primary-dark: #4E9621;
$primary-darker: #254F0C;
$primary-darkest: #193408;
$gradient-primary: linear-gradient($primary-dark 0%, $primary-lightest 100%);

$accents-light: #F5A45D;
$accents-lighter: #F7CBA3;
$accents-lightest: #FFF0E3;
$accents-dark: #B05A0E;
$accents-darker: #783A04;
$accents-darkest: #482201;
$gradient-accents: linear-gradient($accents-dark 0%, $accents-lightest 100%);

// Design colors
$success: #66B536;
$text-link: $blue;
$link-color: $text-link;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "link":   $text-link,
    "success":  $success,
  ),
  $theme-colors
);

$bg-light-primary: linear-gradient(#F7F7F7 0%, #F6FFF2 100%), #F7F7F7;
$bg-dark-primary: $primary-darkest;


$b-radius-default: 3px;
$c-text-dark: #3A3A3A;
$c-text-gray: #717171;
$c-bg-gray: #CCCCCC;