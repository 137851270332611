// SIGN IN

// General
//
.page-Sign {
  div.flash {
    max-width: calc(90% - 2rem);
    margin-bottom: 0;
    position: absolute;
    top: 3vh;
    left: 50%;
    transform: translateX(-50%);
  }

  h1 {
    font-size: 16px;
  }
}


// NMS login page
//
.page-Sign.theme-default {
  background: $white;

  .body-wrap {
    padding: 0;
    background: $white;

    .content-wrap {
      background: $white;

      .container { padding: 0; }
    }
  }

  .theme-pattern {
    width: 100%;
    height: 400px;
    background: linear-gradient(230.68deg, $primary 6.27%, $primary-light 128.79%), $primary;
  }

  .login {
    width: 90%;
    max-width: 420px;
    margin: -280px auto 0 auto;
    padding: 35px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $white;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);

    .login-form {
      border-top: 1px solid #EEE;
      padding-top: 1rem;
    }

    > img:first-child {
      height: 68px;
      margin-bottom: 50px;
    }

    form,
    form table,
    form table tbody,
    form table tbody tr th {
      width: 100%;
      padding: 0;
      display: block;
    }
    form table tbody tr {
      display: flex;
      flex-direction: column;

      &:hover,
      &:focus { background: $white; }
    }
    form label {
      width: 100%;
      margin: 10px 0 0 0;
      color: #909090;

      &.required { font-weight: normal; }
    }
    form input:not([type="checkbox"]) {
      width: 100%;
    }
    form input[type="checkbox"] {
      height: 20px;
      width: 20px;
      position: relative;
    }
    form input[type="submit"] {
      margin-top: 25px;
    }

    form .invalid-feedback { font-size: inherit; }

    ul.error {
      margin-left: 0;
      margin-bottom: 0;
      padding-left: 0;
      li {
        color: red;
        list-style: none;
      }
    }

  }

  .btn.google {
    margin-bottom: 20px;
    padding: 0;
  }

  .btn.microsoft {
    margin-bottom: 20px;
    padding: 0;
  }


  .forgottenPassword,
  .backToSignIn {
    margin-top: 20px;
    font-size: small;
    text-align: center;
  }

  @media (max-width: 767.98px) {
    .login {
      margin-top: -70px;
      padding: 15px 15px;
    }

    .theme-pattern {
      height: 100px;
    }
  }
}