// Appearance
//

// Select in card-header
// Setup a default a responsive size and breakpoint for card-headers with select boxes
//
.page-Appearance .card > .card-header .select2 { min-width: 180px; }
@include media-breakpoint-down(sm) {
  .page-Appearance .card > .card-header > div:first-child {
    flex-direction: column;

    > div:nth-child(2) {
      margin: 0.5rem 0 0 0 !important;

      .btn {
        margin-left: 1rem;
        padding: 0 !important;
      }
    }
  }
}
