
				@use "sass:map";
				@import "./www/assets/styles/_variables.scss";
		
.TagsManagement {
  .TagForm {
    position: relative;

    &.disable {
      pointer-events: none;
      opacity: 0.3;
    }

    .colorPicker {
      position: absolute;
      z-index: 1;
      top: -1rem;
      left: 50%;
      translate: -50% 0;
      padding: 40px 60px 30px 42px;
      margin-top: -40px;
      > .dot {
        position: absolute;
        width: 18px;
        height: 18px;
        transition: translate 180ms, scale 180ms, box-shadow 180ms;

        &.active, &:hover {
          z-index: 2;
        }
      }
      &:hover {
        $spiral-base: 40px;
        $spiral-opening: 0px;
        $spiral-period: 19deg;
        $spiral-shift: 163deg;
        @for $i from 1 through 15 {
          .dot:nth-last-child(#{$i}) {
            transition-delay: #{$i*45}ms;
            translate: calc( (#{$spiral-base} + #{$spiral-opening} * #{$i} ) * #{'cos'}( #{$spiral-shift} + #{$spiral-period} * #{$i} ) ) calc( (#{$spiral-base} + #{$spiral-opening} * #{$i} ) * #{'sin'}( #{$spiral-shift} + #{$spiral-period} * #{$i} ) );
          }
        }
        .dot {
          scale: 1.1;
          box-shadow: 0 0 8px rgba(0,0,0,0.2);
        }
      }
    }
    .preview {
      text-align: center;
      scale: 2;

      > * {
        box-shadow: 1px 2px 4px rgba(0,0,0, 0.2);
      }
    }
    .invalid input {
      outline: 2px solid red;
    }
    .badge{
      transition: all 0.2s;
      &.checked {
        translate: 0 -4px;
      }
    }
    .badge-dark, .badge-danger {
      color: #ffffff !important;
    }
  }

  .TagList {
    table td:first-child {
      width: 100%; // fill rest of width of table with TagName column
    }
  }
}
