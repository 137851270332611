@keyframes fade-in-up {
  0% {opacity: 0; translate: 0 100%; }
  100% {opacity: 1; translate: 0 0;}
}

.a-fade-in-up {
  animation: fade-in-up;
  animation-timing-function: ease-in;
  animation-duration: 400ms;
}


@keyframes fade-in {
  0% {opacity: 0; }
  100% {opacity: 1; }
}

.a-fade-in {
  animation: fade-in;
  animation-timing-function: ease-in;
  animation-duration: 400ms;
}


// Blinking
.blink {
  animation: blinker .5s linear infinite;
}

.blink-1 {
  animation: blinker 1s linear infinite;
}

.blink-2 {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}