
				@use "sass:map";
				@import "./www/assets/styles/_variables.scss";
		
.TagsAssignmentContainer {
  .tag-filter {
    input {
      height: auto;
    }
  }
  .Tag {
    display: inline-block;
    position: relative;

    &:hover > .tag-unlink {
        opacity: 1;
    }

    > .tag-unlink {
      position: absolute;
      opacity: 0;
      right: -2px;
      top: -2px;
      border-radius: 50%;
      color: white;
      cursor: pointer;
      transition: opacity 200ms;
      background-color: red;
      width: 12px;
      height: 12px;
      line-height: 12px;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }
  }
}

td.col-tags {
  position: relative;
}

.datagrid {
  .TagsAssignmentContainer {
    &.opened .tag-add-button {
      opacity: 1;
    }

    > .tags {
      padding-right: 30px;
      position: relative;
    }

    .tag-add-button {
      z-index: 1;
      position: absolute;
      right: 0.5rem;
      top: 25%;
      translate: 0 -50%;
      opacity: 0;
    }
  }
  tr:hover .tag-add-button {
    opacity: 1;
  }
}

.dashboard-about-project {
  .TagsAssignmentContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;

    &.opened,
    &:hover {
      .tag-add-button {
        opacity: 1;
      }
    }

    .tag-add-button {
      opacity: 0;
    }
  }
}
