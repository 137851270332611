.page-observer {
  .content-wrap { background: #F7F7F7; }

  .card-header { background: $blue; }

  .results-number {
    padding: 0.25rem 0.625rem;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 0.25rem;
    background-color: #F3F3F3;

    p { margin: 0; }
    .title {
      margin-right: 1rem;
      font-weight: 500;
    }
  }
}

@include media-breakpoint-down(md) {
  .page-observer .content-wrap > .container { padding: 0.5rem; }
}