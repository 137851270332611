.questionary-comment {

  &.questionary-comment-new .status {
    background: #DFE;
  }

  &.questionary-comment-invalid .status {
    background: #FFD8DA;
  }

  .actions {
    text-align: right;
  }

  .actions i {
    font-size: 18px;
    margin-right: 5px;
  }
}
