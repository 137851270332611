.unsubscribe {
  margin: 0 auto;
  max-width: 400px;

  h1 {
    text-align: center;
    font-weight: normal;
    margin: 0 0 30px 0;
    padding: 0;
  }

  p {
    font-size: 1rem;
    text-align: center;
  }

  i {
    color: #aaaaaa;
  }

  input[type="text"] {
    margin: 0 0 20px 0;
  }

  .left {
    padding: 7px 0 0 10px;
    opacity: 0.7;
  }

  .left input[type="checkbox"] {
    cursor: pointer;
  }

  .right {
    padding-right: 10px;

    input[type="submit"] {
      padding: 12px 24px;
      border: solid 1px #27639d;
      border-radius: $border-radius;
      background: #3484D2;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
      letter-spacing: 0.04rem;
      color: #fff;
      cursor: pointer;
      transition: border 250ms, background 250ms, box-shadow 250ms, color 250ms;

      &:hover {
        border: solid 1px #3484D2;
        background: #f7f7f7;
        color: #3484D2;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
        transition: border 250ms, background 250ms, box-shadow 250ms, color 250ms;
      }
    }
  }
}
