@keyframes ublaboo-spinner-icon {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ublaboo-spinner-icon {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ublaboo-spinner-icon > span {
  animation-duration: 2s;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-name: ublaboo-spinner-icon;
}

@keyframes ublaboo-spinner-small {
  0% {
    transform: translate(21.3px, 2.2px);
  }

  11.1% {
    transform: translate(8.1px, 25.2px);
  }

  22.2% {
    transform: translate(12.7px, -0.7px);
  }

  33.3% {
    transform: translate(17.2px, 25.2px);
  }

  44.4% {
    transform: translate(4.2px, 2.2px);
  }

  55.5% {
    transform: translate(24.1px, 19.5px);
  }

  66.6% {
    transform: translate(-0.3px, 10.3px);
  }

  77.7% {
    transform: translate(25.8px, 10.3px);
  }

  88.8% {
    transform: translate(1.2px, 19.3px);
  }

  100% {
    transform: translate(21.3px, 2.2px);
  }
}

@-webkit-keyframes ublaboo-spinner-small {
  0% {
    transform: translate(21.3px, 2.2px);
  }

  11.1% {
    transform: translate(8.1px, 25.2px);
  }

  22.2% {
    transform: translate(12.7px, -0.7px);
  }

  33.3% {
    transform: translate(17.2px, 25.2px);
  }

  44.4% {
    transform: translate(4.2px, 2.2px);
  }

  55.5% {
    transform: translate(24.1px, 19.5px);
  }

  66.6% {
    transform: translate(-0.3px, 10.3px);
  }

  77.7% {
    transform: translate(25.8px, 10.3px);
  }

  88.8% {
    transform: translate(1.2px, 19.3px);
  }

  100% {
    transform: translate(21.3px, 2.2px);
  }
}

@keyframes ublaboo-spinner-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ublaboo-spinner-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ublaboo-spinner {
  line-height: 0;
  display: inline-block;
  margin: auto;
  position: relative;
  margin: 0 1em -11px 1em;
  top: 1px;
  opacity: 0;
  animation-duration: 150ms;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-name: ublaboo-spinner-in;
  animation-fill-mode: forwards;
}

.ublaboo-spinner > i {
  position: absolute;
  background-color: #37434f;
  left: 0;
  top: 0;
  animation-duration: 6s;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

.ublaboo-spinner > i:nth-of-type(2) {
  animation-delay: -1.5s;
}

.ublaboo-spinner > i:nth-of-type(3) {
  animation-delay: -3s;
}

.ublaboo-spinner > i:nth-of-type(4) {
  animation-delay: -4.5s;
}

.ublaboo-spinner.ublaboo-spinner-small {
  width: 28.0px;
  height: 28.0px;
}

.ublaboo-spinner.ublaboo-spinner-small > i {
  width: 4.0px;
  height: 4.0px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  animation-name: ublaboo-spinner-small;
}